<template>
  <div id="banner">
    <div class="left" data-aos="fade-down">
      <h1>Cloeren Aerospace</h1>
      <h2>ITAR Registered &amp; AS9100 Certified</h2>
    </div>
    <div class="right">
      <div data-aos="fade-left" data-aos-duration="300">
        <img :src="require(`@/assets/images/ITAR.png`)" width="220" alt="" />
      </div>
      <div data-aos="fade-left" data-aos-duration="600">
        <img :src="require(`@/assets/images/AS-9100.png`)" width="220" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#banner {
  width: 100%;
  padding: 40px 60px;
  background: $primary;
  background-image: url('@/assets/images/cl-feedblock-bg.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include breakpoint(md-max) {
    flex-direction: column;
    padding-right: 40px;
    padding-left: 40px;
  }
  .left {
    @include breakpoint(md-min) {
      margin-right: 20px;
    }
    @include breakpoint(md-max) {
      text-align: center;
    }
    h1 {
      color: $accent;
      font-size: 40px;
      margin-top: 0;
      margin-bottom: 0.25em;
      line-height: 1;
    }
    h2 {
      margin: 0;
      line-height: 1;
      font-size: 32px;
      color: white;
    }
  }
  .right {
    display: flex;
    @include breakpoint(md-max) {
      margin-top: 2em;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        margin-right: 2em;
      }
      h1,
      p {
        margin: 0;
        line-height: 1;
        text-transform: uppercase;
      }
      h1 {
        letter-spacing: 0.05em;
        margin-bottom: 0.25em;
      }
      p {
        letter-spacing: 0.15em;
        font-size: 16px;
      }
    }
  }
}
</style>
